import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import ClubTeamPageTemplate from "./ClubTeamPageTemplate"

const ClubTeamPage = ({ data }) => {
  const { frontmatter: fm } = data.markdownRemark

  return (
    <>
      <MyHelmet title={fm.title} description={fm.subheading} />
      <ClubTeamPageTemplate {...fm} />
    </>
  )
}

export default ClubTeamPage

export const clubTeamPageQuery = graphql`
  query ClubTeamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        subheading
        image {
          childImageSharp {
            gatsbyImageData(width: 1216, placeholder: BLURRED)
          }
        }
        headingBoard
        vorstand1
        vorstand2
        kasse
        schrift
        sportwart
        jugendwart
        eventwart
        beisitzer {
          uuid
        }
        headingContact
        contact {
          name
          uuid
        }
        headingTrainer
        trainer {
          uuid
        }
      }
    }
  }
`
