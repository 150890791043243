import React from "react"
import Header from "../components/Header"
import Image from "../components/Image"
import { Container, SectionHeading } from "../components/Sections"
import { RevealFadeIn } from '../components/Reveal'
import { Trainer, InfoShort, InfoLong, byUuid } from "../components/Person"

const TrainerByUuid = byUuid(Trainer)
const InfoShortByUuid = byUuid(InfoShort)
const InfoLongByUuid = byUuid(InfoLong)

function ClubTeamPageTemplate({
  heading,
  subheading,
  image,
  headingBoard,
  vorstand1,
  vorstand2,
  kasse,
  schrift,
  sportwart,
  jugendwart,
  eventwart,
  beisitzer,
  headingContact,
  contact,
  headingTrainer,
  trainer,
}) {
  return (
    <div>
      <Header heading={heading} subheading={subheading} />
      { image ? <>
            <Container className="max-w-3xl text-center -mt-20 sm:-mt-24 lg:-mt-28 pt-16">
              <Image className="rounded-md" image={image.image || image} alt={image.alt || 'Illustration Team'} />
            </Container>
          </>
        :
          ''
      }
      <Container>
        <SectionHeading>{headingBoard}</SectionHeading>
        <div className="mt-6 sm:mb-8">
          <InfoShortByUuid uuid={vorstand1} />
          <InfoShortByUuid uuid={vorstand2} />
          <InfoShortByUuid uuid={sportwart} />
          <InfoShortByUuid uuid={jugendwart} />
          <InfoShortByUuid uuid={kasse} />
          <InfoShortByUuid uuid={eventwart} />
          <InfoShortByUuid uuid={schrift} />
          { beisitzer.map((b, i) => <InfoShortByUuid uuid={b.uuid} key={i}/>) }
        </div>
      </Container>
      <Container>
        <SectionHeading>{headingContact}</SectionHeading>
        { contact.map(({name, uuid}, idx) => (
          <div key={idx}>
            <h3 className="text-xl mt-6 font-bold dark:text-gray-200">{name}</h3>
            <InfoLongByUuid uuid={uuid}/>
          </div>
        ))}
      </Container>
      <RevealFadeIn>
        <Container>
          <SectionHeading>{headingTrainer}</SectionHeading>
          <div className="grid grid-cols-1 justify-items-center mt-6 gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-3 lg:gap-y-12 xl:gap-x-8 sm:mb-8">
            {trainer.map(({ uuid }, idx) => (
              <TrainerByUuid uuid={uuid} key={idx} />
            ))}
          </div>
        </Container>
      </RevealFadeIn>
    </div>
  )
}

export default ClubTeamPageTemplate
